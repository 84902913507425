<template>
  <v-img
    v-if="logoSrc || isGlobalAdmin"
    lazy-load
    :src="isGlobalAdmin ? deguraLogo : logoSrc"
    height="55px"
    width="91px"
    max-width="100px"
    alt="logo"
    contain
    center
    :class="{ 'mr-1': !$vuetify.breakpoint.smAndDown }"
  >
  </v-img>
</template>

<script>
import { ref, getCurrentInstance, computed } from '@vue/composition-api';
import themeConfig from '@themeConfig';
import { ROLE_ADMIN } from '../../../constants';

import { config } from '../../../../appConfig';

export default {
  setup() {
    const vm = getCurrentInstance().proxy;
    const assetsUrl = ref('');
    const companyConfig = computed(() => vm.$store.state.auth.companyConfig);
    const isGlobalAdmin = computed(() => {
      return vm.$store.getters.activeRole === ROLE_ADMIN || vm.$store.getters.activeRole === ROLE_KEY_ACCOUNT;
    });
    const logoSrc = computed(() => {
      return !isGlobalAdmin.value && companyConfig.value.logo ? `${companyConfig.value.logo}` : '';
    });

    assetsUrl.value = config.variables.ASSETS_BASE_URL;

    return {
      isGlobalAdmin,
      assetsUrl,
      companyConfig,
      logoSrc,
      deguraLogo: themeConfig.app.logo,
    };
  },
};
</script>
