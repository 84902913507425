import axios from '@/plugins/axios';
import { LANGUAGE_FORMAL, LANGUAGE_INFORMAL } from '@/constants';
import { config } from '../../../appConfig';

const ASSETS_URL = config.variables.ASSETS_BASE_URL;

const buildQuery = (offset, limit, search) => {
  let query = '';

  if (offset || offset === 0) {
    query += `&offset=${offset}`;
  }

  if (limit || limit === 0) {
    query += `&limit=${limit}`;
  }

  if (search) {
    query += `&searchString=${search}`;
  }

  return query;
};

export const getCompaniesList = (offset = 0, limit = 15, search) => {
  const q = buildQuery(offset, limit, search);
  return axios.get(`/companies?${q}`);
};
export const getCompanyById = (companyId) => axios.get(`/companies/${companyId}`);
export const getCompanyConfig = (companyId, type = 'api') => {
  let url = `onboarding/company/${companyId}`;

  if (type !== 'api') {
    url = `${ASSETS_URL}/companies/${companyId}/${companyId}.json`;
  }

  return companyId ? axios.get(url) : null;
};
export const getCompanyWelcomeConfig = (companyId) => axios.get(`${ASSETS_URL}/companies/welcome/${companyId}.json`);

export const getCompanyProviderPlan = (type, companyId, providerKey, planKey) => {
  let baseUrl = `${ASSETS_URL}/providers`;

  if (type === 'custom') {
    baseUrl = `${ASSETS_URL}/companies/${companyId}/providers`;
  }

  if (type === 'api') {
    baseUrl = `/onboarding/${companyId}/plans`;
    return axios.get(baseUrl);
  }
  return axios.get(`${baseUrl}/${providerKey}/plans/${planKey}/plan.json`);
};

export const getProviderDetails = (providerId) => {
  return axios.get(`/providers/${providerId}`);
};

export const deactivateCompany = (companyId) => axios.delete(`/companies/${companyId}/deactivate`);

export const createCompany = async (payload) => {
  const data = {
    companyName: payload.name,
    employeeFacingName: payload.displayName,
    languagePreference: {
      language: payload.language.slice(0, 2),
      form: payload.useFormalLang ? LANGUAGE_FORMAL : LANGUAGE_INFORMAL,
    },
  };

  const createdCompanyId = await axios.post('/companies', data).catch((err) => {
    throw new Error(err);
  });
  return createdCompanyId.data;
};

export const changeCompany2faSettings = (id, settings) => {
  return axios.post(`/companies/${id}?settings2fa=${settings}`);
};

export const updateCompany = (id, payload) => {
  return axios.put(`/companies/${id}`, payload);
};
