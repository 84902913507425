const globalAdminRoutes = [
  {
    path: '/companies',
    name: 'companies',
    component: () => import('@/views/global-admin/GlobalAdminCompanies.vue'),
    meta: {
      layout: 'content',
      resource: 'GlobalAdmin',
      titleKey: 'Companies',
    },
  },
  {
    path: '/companies/:id',
    name: 'company',
    component: () => import('@/views/global-admin/GlobalAdminCompany.vue'),
    meta: {
      layout: 'content',
      titleKey: 'companyTitle',
    },
  },
  {
    path: '/companies/:id/onboard',
    name: 'company',
    component: () => import('@/views/global-admin/GlobalAdminCompanyOnboard.vue'),
    meta: {
      layout: 'content',
      titleKey: 'companyTitle',
    },
  },
  {
    path: '/content-management',
    name: 'content-management',
    component: () => import('@/views/global-admin/GlobalAdminCustomerInformation.vue'),
    meta: {
      layout: 'content',
      resource: 'GlobalAdmin',
      titleKey: 'Content management',
    },
  },
];

export default globalAdminRoutes;
